<template>
  <div class="depts">
    <div class="head">
      <ul class="tabs">
        <li
          v-for="(item, index) in tabs"
          :key="index"
          :class="{ current: item.value == current }"
          @click="onItemClick(item)"
        >
          {{ item.key }}
          <el-divider
            v-if="index !== tabs.length - 1"
            direction="vertical"
          ></el-divider>
        </li>
      </ul>
    </div>
    <el-row :gutter="18">
      <el-col
        :span="8"
        v-for="item in deptList"
        :key="item.id"
        @click.native="onDetail(item)"
      >
        <span class="name"
          >{{ item.deptName
          }}<span
            class="outChainUrl"
            v-if="item.outChainUrl && item.outChainUrl !== ''"
            >（外）</span
          ></span
        >
        <span class="sub">总留言数：{{ item.asksCount }}</span>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getDeptNewList } from "@/api/dept";
export default {
  name: "depts",
  data() {
    return {
      current: 2,
      tabs: [
        {
          key: "部门在线",
          value: 2,
        },
        {
          key: "区县在线",
          value: 1,
        },
        // {
        //   key: "区县(部门)在线",
        //   value: 3,
        // },
        // {
        //   key: "社区在线",
        //   value: 5,
        // },
      ],
      deptList: [],
    };
  },
  mounted() {
    this.getDeptNewList();
  },
  methods: {
    onDetail(item) {
      if (item.outChainUrl && item.outChainUrl !== "") {
        window.open(item.outChainUrl);
      } else {
        this.$router.push({
          path: `/web/deptlist/${item.id}/${item.deptName}/1/null`,
        });
      }
    },
    async getDeptNewList() {
      const res = await getDeptNewList(this.current, 100);
      // console.log(res);
      this.deptList = res.data;
    },
    onItemClick(item) {
      this.current = item.value;
      this.getDeptNewList();
    },
  },
};
</script>

<style lang="scss" scoped>
.depts {
  background: #ffffff;
  .head {
    display: flex;
    align-items: center;
    justify-content: right;
    height: 68px;
    background: url("~images/depts/head-bg.png") top left no-repeat;
    background-size: 810px 68px;
    .tabs {
      display: flex;
      margin-right: 20px;
      li {
        font-size: 16px;
        color: #90949e;
        cursor: pointer;
        &.current {
          font-weight: 700;
          color: #2a2b2e;
        }
      }
    }
  }
  .el-row {
    padding: 20px;
    .el-col {
      height: 68px;
      margin-bottom: 18px;
      padding: 12px 18px;
      background: url("~images/depts/bg.png") left center no-repeat;
      background-size: 245px 68px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .name {
        font-size: 16px;
        color: #3970b3;
        .outChainUrl {
          color: #ff0000;
        }
      }
      .sub {
        font-size: 13px;
        color: #999999;
      }
    }
  }
}
</style>
